import React from 'react'
import { Form, Formik } from 'formik'

import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'

export default function ExpenseForm({ onCancel, onSubmit, loading, initialValues }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', note: '', count: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input name="name" label="Эслатма" validate={required} />
                        <Input name="count" label="Количество" type="number" step="1" validate={required} disabled={loading} />
                        <Input component="textarea" name="note" label="Примечание" validate={required} disabled={loading} />
                    </div>
                </div>
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" disabled={loading} />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                </div>
            </Form>
        </Formik>
    )
}
