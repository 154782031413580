import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import ProductForm from './ProductForm'
import { useMessage } from '../hooks/message'

export default function ProductCreate({ onCancel, categoryList, reload, brandList }) {
    const [showMessage] = useMessage()
    const products = usePostRequest({ url: PRODUCT_LIST })

    async function onSubmit(data) {
        const { success, error } = await products.request({ data })
        if (success) {
            reload.request()
            onCancel()
            showMessage('Успешно добавлен', 'is-success')
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Қўшиш товар</b></h1>

            <ProductForm
                loading={products.loading}
                categoryList={categoryList}
                brandList={brandList}
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>
    )
}
