import { StyleSheet } from 'aphrodite'
import React from 'react'

import Layout from '../../components/Layout'
import ExpenseCreate from './expense-create'
import { useModal } from '../../hooks/modal'
import { useLoad } from '../../hooks/request'
import { EXPENSE_REPORT_LIST, INCOME_LIST } from '../../urls'
import IncomeDetailTable from '../../components/IncomeDetailTable'

export default function Expenses() {
    const expenseReport = useLoad({ url: EXPENSE_REPORT_LIST })

    const [showCreateModal, setShowCreateModal] = useModal(<ExpenseCreate expenseReport={expenseReport} onCancel={() => setShowCreateModal()} />, styles.modal)

    const categories = useLoad({ url: INCOME_LIST })

    return (
        <Layout showCreateModal={showCreateModal}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                {(categories.response ? categories.response.results : []).map((category) => (
                    <IncomeDetailTable incomeId={category?.id} category={category} />
                ))}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
