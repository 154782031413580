import React, { useState } from 'react'
import Layout from '../../../components/Layout'
import SettingsTabs from '../../../components/settings/SettingsTabs'
import Card from '../../../components/common/Card'
import Button from '../../../components/common/Button'
import Table from '../../../components/common/Table'
import { useModal } from '../../../hooks/modal'
import { useLoad } from '../../../hooks/request'
import CategoryCreate from './CategoryCreate'
import CategoryItem from './category-item'
import { CATEGORY_LIST } from '../../../urls'
import { useQueryParams } from '../../../hooks/queryString'

export default function Category() {
    const [page, setPage] = useState(1)
    const params = useQueryParams()

    const category = useLoad({ url: CATEGORY_LIST, params: { page, ...params, size: 1000 } }, [page, params])

    const [showCreateModal, hideCreateModal] = useModal(
        <CategoryCreate
            onSuccess={() => {
                hideCreateModal()
                category.request()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    const categories = category.response ? category.response.results : []

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                <Card>
                    <div className="columns">
                        <div className="is-size-4 column">Категория</div>

                        <div className="column is-narrow">
                            <Button onClick={showCreateModal} text="Қўшиш" icon="add-outline"
                                className="is-pulled-right is-link is-outlined" />
                        </div>
                    </div>

                    <Table
                        loading={categories.loading}
                        items={categories}
                        columns={{ index: '№', category: 'Асосий Категория/Категория', date: 'Сана', buttons: '' }}
                        totalCount={categories.response ? categories.response.count : 0}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <CategoryItem index={index} currency={categories} item={item} key={item.id} onDelete={category.request} onUpdate={category.request} status={category} />}
                    />
                </Card>
                <br />
            </div>
        </Layout>
    )
}
