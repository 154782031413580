import React from 'react'

import { useModal } from '../../hooks/modal'
import ExpenseUpdate from './expense-update'
import { maxLength } from '../../utils/string'
import { EXPENSE_REPORT_DETAIL } from '../../urls'
import Button from '../../components/common/Button'
import { useDeleteRequest } from '../../hooks/request'

export default function ExpensesColumn({ item, index, expenseReport }) {
    const productRemove = useDeleteRequest({ url: EXPENSE_REPORT_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            item.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ExpenseUpdate
            clients={item}
            item={item}
            onCancel={() => {
                expenseReport.request()
                hideUpdateModal()
            }}
        />,
    )

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">{item?.name}</td>
            <td className="is-size-6">{item?.count}</td>
            <td className="is-size-6">{maxLength(item?.note, 24)}</td>
            <td>
                <div style={{ display: 'flex' }} className="has-text-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
