import React from 'react'

export default function ClientCreate() {
    return (
        <div>
            <h1 className="title has-text-centered is-capitalized">
                <b>Қўшиш новый клиента</b>
            </h1>
        </div>
    )
}
