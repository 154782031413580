import React from 'react'

import ExpenseForm from './expense-create-form'
import { useMessage } from '../../hooks/message'
import { EXPENSE_LIST } from '../../urls'
import { usePostRequest } from '../../hooks/request'

export default function ExpenseCreate({ onCancel, categoryList, expenseReport, brandList }) {
    const products = usePostRequest({ url: EXPENSE_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success, error } = await products.request({ data })
        if (success) {
            expenseReport.request()
            onCancel()
        }
        if (error) showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Қўшиш</b>
            </h1>
            <ExpenseForm loading={products.loading} categoryList={categoryList} brandList={brandList} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
