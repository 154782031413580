import React from 'react'
import { format } from '../utils/number'

export default function CheckView({ item, order }) {
    // eslint-disable-next-line max-len
    const result = item.reduce((total, currentValue) => total + parseInt(currentValue?.price * currentValue.count, 10), 0)

    return (
        <div>
            <table style={{ width: '100%' }} className="table">
                <tr>
                    <th>
                        {' '}
                        {order.client.name} - ({order.client.phoneNumber})
                    </th>
                    <th />
                    <th />
                    <th />
                </tr>
                {item.map((element) => (
                    <tr key={element.id}>
                        <td className="">{element.product.name}</td>
                        <td className="">{element.count} </td>
                        <td className="">{format(element.price)}</td>
                        <td className="">{format(element.price * element.count)}</td>
                    </tr>
                ))}

                <b>
                    <span className="is-flex is-justify-content-center">
                        <p className="ml-2" />
                    </span>
                </b>
            </table>
            <b>
                <div className="columns">
                    <div className="column">
                        <p className="tag is-success is-light is-size-5">Итого: {format(result)} СЎМ</p>
                    </div>
                </div>
            </b>
        </div>
    )
}
