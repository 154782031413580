import { Form, Formik } from 'formik'
import React from 'react'
import Input from '../common/Input'
import { required } from '../../utils/validators'
import Button from '../common/Button'

export default function UserForm({ onSubmit, user, loading }) {
    return (
        <Formik initialValues={user} onSubmit={onSubmit}>
            <Form>
                <Input name="email" label="Электрон почта" placeholder="" disabled validate={required} />
                <Input name="lastName" label="Фамилия" placeholder="Введите фамилию" validate={required} />
                <Input name="firstName" label="Имя" placeholder="Введите имя" disabled={loading} validate={required} />
                <Button loading={loading} text="Сақлаш" type="submit" icon="checkmark-outline" disabled={loading} className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
