import { StyleSheet } from 'aphrodite'
import React, { useState } from 'react'

import ExpensesColumn from './Column'
import { useModal } from '../../hooks/modal'
import { useLoad } from '../../hooks/request'
import Layout from '../../components/Layout'
import ExpenseCreate from './expense-create'
import { EXPENSE_REPORT_LIST } from '../../urls'
import Table from '../../components/common/Table'
import Button from '../../components/common/Button'
import ExpenseAdd from './expense-add'
import ExpenseRemove from './expense-remove'

export default function Calculator() {
    const expenseReport = useLoad({ url: EXPENSE_REPORT_LIST })
    const [page, setPage] = useState(1)

    const productList = [
        { id: 1, date: '5.12.2024', name: 'Ot', count: '20', amount: 20000000, note: 'Oq va qora otlar', buttons: '' },
        { id: 2, date: '5.12.2024', name: 'Mol', count: '34', amount: 120000000, note: 'Barchasi', buttons: '' },
        { id: 3, date: '5.12.2024', name: 'Qo`y', count: '67', amount: 3400000, note: 'Barchasi', buttons: '' },
        { id: 4, date: '5.12.2024', name: 'Echki', count: '28', amount: 1200000, note: 'Barchasi', buttons: '' },
    ]

    const [showCreateModal, setShowCreateModal] = useModal(<ExpenseCreate expenseReport={expenseReport} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showAddModal, setShowAddModal] = useModal(<ExpenseAdd expenseReport={expenseReport} onCancel={() => setShowAddModal()} />, styles.modal)
    const [showRemoveModal, setShowRemoveModal] = useModal(<ExpenseRemove expenseReport={expenseReport} onCancel={() => setShowRemoveModal()} />, styles.modal)

    return (
        <Layout showCreateModal={showCreateModal}>
            <div className="is-flex is-justify-content-space-between mb-3 is-align-content-center">
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <h2 style={{ fontSize: 22, fontWeight: 'bold' }}>Калькулятор</h2>
                </div>

                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Button text="- Вычесть" className="button is-right is-link is-outlined ml-2" onClick={showRemoveModal} />
                    <Button text="+ Добавлять" className="button is-right is-link is-outlined ml-2" onClick={showAddModal} />
                    <Button text="Создать новый" className="button is-right is-link is-outlined ml-2" onClick={showCreateModal} />
                </div>
            </div>

            <div className="mt-4">
                <Table
                    loading={expenseReport.loading}
                    items={productList}
                    columns={{ index: '№', name: 'Имя', count: 'Количество (шт)', note: 'Примечание', buttons: '' }}
                    totalCount={expenseReport.response ? expenseReport.response.count : 0}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => <ExpensesColumn expenseReport={expenseReport} index={index} key={item.id} item={item} />}
                />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
