import React from 'react'
import { Form, Formik } from 'formik'

import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'
import Select from '../../components/common/Select'

export default function ExpenseRemoveForm({ onCancel, onSubmit, loading, initialValues }) {
    const products = [
        { name: 'Ot', id: 'ot' },
        { name: 'Mol', id: ',mol' },
        { name: 'Qo`y', id: 'qoy' },
        { name: 'Echki', id: 'echki' },
    ]

    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', count: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Select name="name" options={products} />
                        <Input name="count" label="Количество" type="number" step="1" validate={required} disabled={loading} />
                    </div>
                </div>
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" disabled={loading} />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                </div>
            </Form>
        </Formik>
    )
}
