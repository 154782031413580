import React from 'react'
import { usePostRequest } from '../../../hooks/request'
import { CATEGORY_LIST } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import CategoryForm from './CategoryForm'

export default function CategoryCreate({ onSuccess, onCancel }) {
    const brandCreate = usePostRequest({ url: CATEGORY_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await brandCreate.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Қўшиш</b>
            </h1>

            <CategoryForm loading={brandCreate.loading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
}
