import React, { useState } from 'react'
import { useLoad } from '../hooks/request'
import { INCOME_ITEM_LIST } from '../urls'
import Table from './common/Table'
import IncomeDetailItem from './IncomeDetailItem'
import IncomeDetailCreate from './IncomeDetailCreate'
import DateFilter from './common/DateFilter'
import { useModal } from '../hooks/modal'
import Button from './common/Button'
import { useQueryParams } from '../hooks/queryString'

export default function IncomeDetailTable({ incomeId, category }) {
    const [page, setPage] = useState(1)
    const params = useQueryParams()
    const expenseReport = useLoad({ url: INCOME_ITEM_LIST, params: { income: incomeId, ...params } }, [params])
    const [showCreateModal, hideCreateModal] = useModal(<IncomeDetailCreate onCancel={() => hideCreateModal()}
        expenseReport={expenseReport}
        incomeId={incomeId} />)

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
            .format(value)
            .replace(/,/g, ' ')
    }
    return (

        <div>
            <div className="is-flex is-justify-content-space-between mb-3 is-align-content-center">
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <h2 style={{ fontSize: 22, fontWeight: 'bold', gap: 10 }}>{category.name}</h2>

                </div>

                <div className="column is-narrow is-flex">
                    <DateFilter className="mr-2" name="start_date" />
                    <DateFilter className="mr-2" name="end_date" />
                    <Button onClick={showCreateModal} text="Қўшиш"
                        className="is-pulled-right is-link is-outlined" />
                </div>
            </div>
            <div className="mt-4">
                <Table
                    loading={expenseReport.loading}
                    items={expenseReport.response ? expenseReport.response.results : []}
                    columns={{
                        index: '№',
                        name: 'Маҳсулот номи',
                        count: 'Маҳсулот сони',
                        amount: 'Маҳсулот нархи',
                        date: 'Сана',
                        buttons: ''
                    }}
                    totalCount={expenseReport.response ? expenseReport.response.count : 0}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => (
                        <IncomeDetailItem expenseReport={expenseReport} index={index} key={item.id}
                            item={item} />
                    )}
                />
            </div>
            <div className="is-pulled-right mt-2 is-size-6 is-bold" style={{ fontWeight: 'bold' }}>
                Баланс: {format(expenseReport.response ? expenseReport.response.totalPrice : 0)} Сум
            </div>

        </div>

    )
}
