import React from 'react'

import ExpenseForm from './expense-create-form'
import { useMessage } from '../../hooks/message'
import { EXPENSE_REPORT_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'

export default function ExpenseUpdate({ onCancel, item }) {
    const [showMessage] = useMessage()
    const client = usePutRequest({ url: EXPENSE_REPORT_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data, action) {
        const { success, error } = await client.request({ data })
        if (success) {
            onCancel()
            action.resetForm()
        }
        if (error) showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
    }

    return (
        <div>
            <h1 className="title has-text-centered is-capitalized">
                <b>Ўзгартириш</b>
            </h1>
            <ExpenseForm loading={client.loading} initialValues={item} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
