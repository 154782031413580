import { StyleSheet } from 'aphrodite'
import React, { useState } from 'react'
import ExpensesColumn from './Column'
import { useModal } from '../../hooks/modal'
import { useLoad } from '../../hooks/request'
import Layout from '../../components/Layout'
import ExpenseCreate from './expense-create'
import { CATEGORY_LIST, EXPENSE_LIST } from '../../urls'
import Table from '../../components/common/Table'
import Button from '../../components/common/Button'
import DateFilter from '../../components/common/DateFilter'
import FilterSelect from '../../components/common/FilterSelect'
import { useQueryParams } from '../../hooks/queryString'

export default function Earnings() {
    const [page, setPage] = useState(1)
    const [params, setParams] = useState({})

    const searchParam = useQueryParams()
    const expense = useLoad(
        {
            url: EXPENSE_LIST,
            params: { page, ...params, ...searchParam },
        },
        [searchParam, page, params],
    )
    const [selectCategory, setSelectCategory] = useState()
    // Fetch categories
    const { response: categoryResponse } = useLoad({ url: CATEGORY_LIST, params: { size: 1000 } })
    const categoryItems = categoryResponse ? categoryResponse.results : []

    // Fetch subcategories based on selected category
    const { response: subcategoryResponse } = useLoad({
        url: CATEGORY_LIST,
        params: { parent: selectCategory?.category, size: 1000 }
    }, [selectCategory])
    const subcategoryItems = subcategoryResponse ? subcategoryResponse.results : []

    const productList = expense.response ? expense.response.results : []

    const [showCreateModal, setShowCreateModal] = useModal(<ExpenseCreate expenseReport={expense} onCancel={() => setShowCreateModal()} />, styles.modal)

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
            .format(value)
            .replace(/,/g, ' ')
    }
    return (
        <Layout showCreateModal={showCreateModal}>
            <div className="is-flex is-justify-content-space-between mb-3 is-align-content-center">
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <FilterSelect
                        setSelectCategory={setSelectCategory}
                        setSearchParams={setParams} placeholder="Асосий Категория" name="category"
                        options={categoryItems.filter((item) => item.parent === null)} />
                    <FilterSelect setSearchParams={setParams} placeholder="Категория" name="subcategory"
                        options={subcategoryItems} />

                    <DateFilter name="start_date" />
                    <DateFilter name="end_date" />

                    <Button text="Қўшиш" className="button is-right is-link is-outlined ml-2" onClick={showCreateModal} />
                </div>
            </div>

            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <div style={{ border: '1px solid green', padding: 10, color: 'green' }}>
                    <p>
                        <b>Кирим:</b>
                        <span className="ml-2">{format(expense.response ? expense.response.totalIncomeAmount : 0)} Сум</span>
                    </p>
                </div>

                <div style={{ border: '1px solid red', padding: 10, color: 'red' }}>
                    <b>Чиқим:</b>
                    <span className="ml-2">{format(expense.response ? expense.response.totalExpensesAmount : 0)} Сум</span>
                </div>

                <div style={{ border: '1px solid blue', padding: 10, color: 'blue' }}>
                    <b>Баланс:</b>
                    <span className="ml-2">{format(expense.response ? expense.response.balance : 0)} Сум</span>
                </div>
            </div>

            <div className="mt-4 mb-4">
                <Table
                    loading={expense.loading}
                    items={productList}
                    columns={{
                        index: '№',
                        category: 'Асосий Категория/Категория',
                        subcategory: 'Масул Ходим',
                        status: 'Статус',
                        amount: 'Сумма',
                        date: 'Сана',
                        note: 'Эслатма',
                        buttons: '',
                    }}
                    totalCount={expense.response ? expense.response.count : 0}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => <ExpensesColumn expenseReport={expense} index={index} key={item.id} item={item} />}
                />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
