import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'

export default function UserChangePassword({ onSubmit, loading }) {
    return (
        <Formik initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }} onSubmit={onSubmit}>
            <Form>
                <Input name="oldPassword" type="password" label="Эски парол" placeholder="Введите старый пароль" disabled={loading} validate={required} />
                <Input name="newPassword" type="password" label="Янги парол" placeholder="Введите новый пароль" disabled={loading} validate={required} />
                <Input name="confirmPassword" type="password" label="Янги паролни яна бир марта" placeholder="Введите новый пароль ещё раз" disabled={loading} validate={required} />
                <Button loading={loading} text="Сақлаш" type="submit" icon="checkmark-outline" disabled={loading} className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
