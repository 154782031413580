import React from 'react'
import { useDeleteRequest } from '../../../hooks/request'
import { useModal } from '../../../hooks/modal'
import { getDateTime } from '../../../utils/date'
import { format } from '../../../utils/number'
import Loader from '../../../components/common/Loader'
import CategoryUpdate from './CategoryUpdate'
import { CATEGORY_DETAIL } from '../../../urls'

export default function CategoryItem({ item, category, index }) {
    const brandRemove = useDeleteRequest({ url: CATEGORY_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, setShowUpdateModal] = useModal(
        <CategoryUpdate
            item={item}
            onSuccess={() => {
                category.request()
                setShowUpdateModal()
            }}
            onCancel={() => setShowUpdateModal()}
        />,
    )

    async function onDelete() {
        if (global.confirm('Сизни чинакамдан ўчиришни истайсизми?')) {
            await brandRemove.request()
            category.request()
        }
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                {item?.parentName ? `${format(item.parentName)} / ${format(item.name)}` : format(item.name)}
            </td>
            <td>{getDateTime(item.createdAt)}</td>
            <td>
                <div className="is-pulled-right">
                    {!brandRemove.loading ? <i onClick={onDelete} className="icon pointer ion-md-trash" /> : <Loader className="icon" />}

                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                </div>
            </td>
        </tr>
    )
}
