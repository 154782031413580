import React from 'react'
import Layout from '../../components/Layout'
import { useModal } from '../../hooks/modal'
import { useLoad } from '../../hooks/request'
import Card from '../../components/common/Card'
import Table from '../../components/common/Table'
import Button from '../../components/common/Button'
import IncomeItem from '../../components/IcomeItem'
import SettingsTabs from '../../components/settings/SettingsTabs'
import { INCOME_LIST } from '../../urls'
import IncomeCreate from '../../components/IncomeCreate'

export default function Income() {
    const currency = useLoad({ url: INCOME_LIST })

    const [showCreateModal, hideCreateModal] = useModal(
        <IncomeCreate
            onSuccess={() => {
                hideCreateModal()
                currency.request()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                <Card>
                    <div className="columns">
                        <div className="is-size-4 column">Даромад турлари</div>

                        <div className="column is-narrow">
                            <Button onClick={showCreateModal} text="Қўшиш" icon="add-outline" className="is-pulled-right is-link is-outlined" />
                        </div>
                    </div>

                    <Table loading={currency.loading} items={currency.response ? currency.response.results : []} renderItem={(item, index) => <IncomeItem index={index} currency={currency} item={item} key={item.id} onDelete={currency.request} onUpdate={currency.request} status={currency} />} />
                </Card>
                <br />
            </div>
        </Layout>
    )
}
