import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'

export default function IncomeDetailForm({ onSubmit }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', notes: '', price: '' }}>
            <Form>
                <Input name="name" label="Маҳсулот номи" validate={required} />
                <Input name="notes" label="Маҳсулот сони" validate={required} />
                <Input name="price" type="number" step="0.01" label="Маҳсулот нархи"
                    validate={required} />
                <Button style={{ marginBottom: 12 }} text="Қўшиш" icon="add-outline" type="submit"
                    className="is-success" />
            </Form>
        </Formik>
    )
}
