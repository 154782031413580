import React from 'react'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import Button from './common/Button'
import { useLoad } from '../hooks/request'
import { CLIENT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ClientCreate from './ClientCreate'
import CustomSelect from './common/CustomSelect'

export default function CheckForm({ onCancel, onSubmit, initialValues, active, loading, reload }) {
    const client = useLoad({ url: CLIENT_LIST, params: { size: 1000 } })
    const clientItem = client.response ? client.response.results : []
    const clientOptions = Object.entries(clientItem).map(([, { id, name }]) => ({ id, name: id, label: name }))
    const [showCreateClient, hideCreateClient] = useModal(<ClientCreate reload={reload} active={active} onCancel={() => hideCreateClient()} />)
    return (
        <Formik onSubmit={onSubmit} initialValues={{ ...initialValues, client: initialValues && !isEmpty(initialValues.client) ? { label: initialValues.client.name, name: initialValues.client.id, id: initialValues.client.id } : '' }}>
            <Form>
                <CustomSelect placeholder="Пожалуйста, введите имя клиента..." name="client" label="Клиент" options={clientOptions} /> <br /> <br /> <br />
                <div className="is-pulled-right">
                    <Button onClick={showCreateClient} icon="add-outline" text="Янги мижоз қўшиш" className="is-info align-left" />
                    &nbsp;&nbsp;
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
