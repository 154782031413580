import React from 'react'
import { Form, Formik } from 'formik'
import Button from '../../../components/common/Button'
import Input from '../../../components/common/Input'
import { required } from '../../../utils/validators'
import Select from '../../../components/common/Select'
import { useLoad } from '../../../hooks/request'
import { CATEGORY_LIST } from '../../../urls'

export default function CategoryForm({ onCancel, onSubmit, initialValues, loading }) {
    const category = useLoad({ url: CATEGORY_LIST, params: { size: 1000 } })
    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', parent: '', ...initialValues }}>
            <Form>
                <Select empty name="parent" label="Асосий Категория"
                    options={(category.response ? category.response.results : []).filter((item) => item.parent === null)} />
                <Input name="name" label="Категория" validate={required} />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
