import React from 'react'
import { usePostRequest } from '../hooks/request'
import { INCOME_ITEM_LIST } from '../urls'
import IncomeDetailForm from './IncomeDetailForm'

export default function IncomeDetailCreate({ onCancel, incomeId, expenseReport }) {
    const brandCreate = usePostRequest({ url: INCOME_ITEM_LIST })

    async function onSubmit(data) {
        const { success } = await brandCreate.request({ data: { ...data, income: incomeId } })

        if (success) {
            onCancel()
            expenseReport.request()
        }
    }

    return <IncomeDetailForm loading={brandCreate.loading} onSubmit={onSubmit} onCancel={onCancel} />
}
