import React from 'react'
import { usePutRequest } from '../hooks/request'
import { INCOME_ITEM_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import IncomeDetailForm from './IncomeDetailForm'

export default function IncomeDetailUpdate({ onCancel, onSuccess, item }) {
    const brandUpdate = usePutRequest({ url: INCOME_ITEM_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await brandUpdate.request({ data })

        if (success) {
            showMessage('Успешно изменена', 'is-success')
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Ўзгартириш</b>
            </h1>
            <IncomeDetailForm loading={brandUpdate.loading} initialValues={item} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
}
